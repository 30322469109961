window.ruc_verify = function(valor) {
    valor = trim(valor)
    if (esnumero(valor)) {
        if (valor.length == 8) {
            suma = 0
            for (i = 0; i < valor.length - 1; i++) {
                digito = valor.charAt(i) - '0';
                if (i == 0) suma += (digito * 2)
                else suma += (digito * (valor.length - i))
            }
            resto = suma % 11;
            if (resto == 1) resto = 11;
            if (resto + (valor.charAt(valor.length - 1) - '0') == 11) {
                return true
            }

        } else if (valor.length == 11) {
            suma = 0
            x = 6
            for (i = 0; i < valor.length - 1; i++) {
                if (i == 4) x = 8
                digito = valor.charAt(i) - '0';
                x--
                if (i == 0) suma += (digito * x)
                else suma += (digito * x)
            }
            resto = suma % 11;
            resto = 11 - resto

            if (resto >= 10) resto = resto - 10;
            if (resto == valor.charAt(valor.length - 1) - '0') {
                return true
            }
        }


    }
    return false
}

window.trim = function(cadena) {
    cadena2 = "";
    len = cadena.length;
    for (var i = 0; i <= len; i++) if (cadena.charAt(i) != " ") {
        cadena2 += cadena.charAt(i);
    }
    return cadena2;
}

window.esnumero = function(campo) {
    return (!(isNaN(campo)));
}

window.validateEmail = function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

window.formatDate = function(timestamp) {
    var d = new Date(timestamp),
        yyyy = d.getFullYear(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2),
        dd = ('0' + d.getDate()).slice(-2),
        hh = d.getHours(),
        h = hh,
        min = ('0' + d.getMinutes()).slice(-2),
        ampm = 'AM';

    if (hh > 12) {
        h = hh - 12;
        ampm = 'PM';
    } else if (hh === 12) {
        h = 12;
        ampm = 'PM';
    } else if (hh == 0) {
        h = 12;
    }

    return yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
}

window.truncateString = function(str, num) {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

window.sizeFormated = function(bytes) {
    if (!$.isNumeric(bytes)) {
        return '';
    }

    var quant = {
        GB: 1073741824,
        MB: 1048576,
        KB: 1024,
        B: 1
    };

    for (var unit in quant) {
        if (bytes >= quant[unit]) {
            var size = Math.round(bytes / quant[unit] * 10) / 10;
            return size + ' ' + unit;
        }
    }
}

window.getOS = function(){
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }
    //return "iOS"
    return os;
}

window.ValidarPassword = function(keyword, confirm) {
    var strongRegex = new RegExp("^(?=.{6,})((?=.*[A-Z])|(?=.*[a-z])).*$", "g");
    var enoughRegex = new RegExp("(?=.{6,}).*", "g");
    var conteo = 3;
    if (!enoughRegex.test(keyword)) {
        $('#message_password').removeClass('orange');
        $('#message_password').removeClass('green');
        $('#message_password').addClass('red');
        $('#message_password').html('Ingresa mas caracteres (al menos 6)');
        $(".asignar_keyword").addClass("disabled");
        $('#long_valid').removeClass('green');
        $('#long_valid').addClass('red');
        $('#long_valid span').removeClass('fa-check');
        $('#long_valid span').addClass('fa-times');
    } else {
        conteo--;
        $('#long_valid').removeClass('red');
        $('#long_valid').addClass('green');
        $('#long_valid span').addClass('fa-check');
        $('#long_valid span').removeClass('fa-times');
    }

    if (strongRegex.test(keyword)) {
        conteo--;
        $('#message_password').removeClass('orange');
        $('#message_password').removeClass('red');
        $('#message_password').addClass('green');
        $('#message_password').html('Combinación correcta');
        $(".asignar_keyword").addClass("disabled")
        $('#mayus_valid').removeClass('red');
        $('#mayus_valid').addClass('green');
        $('#mayus_valid span').addClass('fa-check');
        $('#mayus_valid span').removeClass('fa-times');
    } else {

        $('#mayus_valid').removeClass('green');
        $('#mayus_valid').addClass('red');
        $('#mayus_valid span').removeClass('fa-check');
        $('#mayus_valid span').addClass('fa-times');
    }


    if (keyword != confirm || keyword.length == 0) {
        $(".asignar_keyword").addClass("disabled")
        $('#message_password_confirm').removeClass('green');
        $('#message_password_confirm').addClass('red');
        $('#message_password_confirm').html('<span class="fa fa-times"></span> La confirmación de la Palabra Clave no coinciden');
    } else {
        conteo--;
        $('#message_password_confirm').removeClass('red');
        $('#message_password_confirm').addClass('green');
        $('#message_password_confirm').html('<span class="fa fa-check"></span> La Palabra Clave de confirmación coincide');

    }
    if (conteo == 0) {
        $(".asignar_keyword").removeClass("disabled");
        $("#download_keyword").removeAttr("disabled");
        $("#download_keyword").removeClass("disabled");
    } else {
        $(".asignar_keyword").addClass("disabled");
        $("#download_keyword").attr("disabled", "disabled");
        $("#download_keyword").addClass("disabled");
    }

}

window.show_messages = function() {
    $('.message').each(function () {
        new Noty({
            type: $(this).attr("key"),
            timeout: 3000,
            text: $(this).text(),
        }).show();
    });
    $('.message').remove()
}

window.PrevenirClickArriba = function() {
    //Evitar que al hacer click en un href="#" regrese arriba
    $('a[href="#"]').click(function (e) {
        e.preventDefault();
    });
};

window.OnlyNumbers = function() {
    $('input.only_numbers').keyup(function () {
        string = $(this).val();
        $(this).val(string.replace(/[^\d\.]/g, '').replace(/^\.*/, '').replace(/(\.\d*)(.*)/, '$1').replace(/,/, ''));
    });
};

window.NotySuccess = function(msj){
    new Noty({
        type: "success",
        timeout: 3000,
        text: msj,
    }).show();
}
window.NotyError = function(msj){
    new Noty({
        type: "warning",
        timeout: 3000,
        text: msj,
    }).show();
}

window.setMargins = function(_m_top,_m_bottom,_m_left,_m_right){
    m_top = _m_top;
    m_bottom = _m_bottom;
    m_left = _m_left;
    m_right = _m_right;
}
window.markjs = function () {

    // Read the keyword
    var keyword = [];
    $(".mark_search").each(function () {
        if ($(this).hasClass("select2-ajax")) {
            var value = $(this).select2('data');
            if (value) {
                for (var i = 0; i <= value.full.split(" ").length - 1; i++) {
                    if (value.full.split(" ")[i])
                        keyword.push(value.full.split(" ")[i])
                }
            }

        } else if ($(this).hasClass("select2")) {
            var value = $(this).select2('data');
            if (value) {
                for (var i = 0; i <= value.text.split(" ").length - 1; i++) {
                    if (value.text.split(" ")[i])
                        keyword.push(value.text.split(" ")[i])
                }
            }

        } else {
            var value = $(this).val();
            if (value) {
                keyword.push(value)
            }
        }


    })

    if (keyword == null || keyword == "") {

        keyword = new URLSearchParams(window.location.search).get('search')
    }
    // Determine selected options
    var options = {};

    var context = document.querySelector(".markjs");
    if (context) {
        var instance = new Mark(context);
        instance.unmark()
        if (keyword) {
            instance.mark(keyword, {
                accuracy: "partially"
            });
        }

    }


};
